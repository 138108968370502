
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { OrganizationBillingCurrency, OrganizationBillingCycle, OrganizationPlan } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as appAnalytics from '@/helpers/analytics'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  name: 'UserRegister'
})
export default class UserRegister extends Vue {
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly form!: IVForm

  showPassword = false
  formInteracted = false
  editingPassword = false

  formController = new FormController({
    initialModel: {
      acceptTos: false,
      email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
      password: '',
      referredBy: this.$route.query.organization ? 'Organization invite' : undefined,
      referredByOrganizationId: typeof this.$route.query.organization === 'string' ? this.$route.query.organization : undefined,
      referredByUserId: typeof this.$route.query.user === 'string' ? this.$route.query.user : undefined,
      sendNews: true
    },
    validationRules: {
      acceptTos: validationRules.exists,
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ],
      password: [
        ...validationRules.exists,
        ...validationRules.password
      ]
    }
  })

  get plan () {
    return (this.$queryValue('plan') as OrganizationPlan | null) || 'free'
  }

  get billingCycle () {
    return this.$queryValue('billing_cycle') as OrganizationBillingCycle | null
  }

  get billingCurrency () {
    return this.$queryValue('billing_currency') as OrganizationBillingCurrency | null
  }

  get seats () {
    return this.$queryValue('seats')
  }

  get organization () {
    return this.$queryValue('organization')
  }

  get title () {
    if (this.organization) {
      return 'Sign up - Join your team'
    } else {
      return 'Sign up'
    }
  }

  get image () {
    if (this.showPassword) {
      return 2
    } else if (
      this.formController.model.acceptTos &&
      this.formController.model.email &&
      this.formController.model.password
    ) {
      return 3
    } else if (this.editingPassword) {
      return 2
    } else {
      return 1
    }
  }

  get brianImages (): { plan: OrganizationPlan, image: UserRegister['image'], url: string }[] {
    // pre-render all the images for speed when switching between them
    return [
      {
        image: 1,
        plan: 'free',
        url: require('../assets/brian/typing-free.png')
      },
      {
        image: 2,
        plan: 'free',
        url: require('../assets/brian/password-free.png')
      },
      {
        image: 3,
        plan: 'free',
        url: require('../assets/brian/complete-free.png')
      },
      {
        image: 1,
        plan: 'indie',
        url: require('../assets/brian/typing-indie.png')
      },
      {
        image: 2,
        plan: 'indie',
        url: require('../assets/brian/password-indie.png')
      },
      {
        image: 3,
        plan: 'indie',
        url: require('../assets/brian/complete-indie.png')
      },
      {
        image: 1,
        plan: 'growth',
        url: require('../assets/brian/typing-growth.png')
      },
      {
        image: 2,
        plan: 'growth',
        url: require('../assets/brian/password-growth.png')
      },
      {
        image: 3,
        plan: 'growth',
        url: require('../assets/brian/complete-growth.png')
      }
    ]
  }

  created () {
    this.formController.submitHandler = async model => {
      await this.userModule.userLogout()

      const verificationLinkParams: Record<string, string> = {
        plan: this.plan
      }
      if (this.billingCycle) {
        verificationLinkParams.billing_cycle = this.billingCycle
      }
      if (this.billingCurrency) {
        verificationLinkParams.billing_currency = this.billingCurrency
      }
      if (this.seats) {
        verificationLinkParams.seats = this.seats
      }

      await this.userModule.userRegister({
        email: model.email,
        password: model.password,
        referredBy: model.referredBy,
        referredByOrganizationId: model.referredByOrganizationId,
        referredByUserId: model.referredByUserId,
        verificationLinkParams
      })

      analytics.userRegister.track(this, {
        userAuthProvider: 'password',
        userReferredBy: model.referredBy,
        userReferredByOrganizationId: model.referredByOrganizationId,
        userReferredByUserId: model.referredByUserId
      })

      if (model.sendNews) {
        await this.userModule.newsSubscribe({
          email: model.email
        })
      }

      await this.$router.push({
        name: 'user-login',
        query: {
          plan: this.plan,
          registered: '1',
          registered_email: model.email
        }
      })
    }
  }

  mounted () {
    analytics.userRegisterScreen.track(this, {
      organizationPlan: this.plan,
      userAuthProvider: 'password'
    })
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userRegisterForm.track(this, {
        userAuthProvider: 'password'
      })
      analytics.userRegister.time()
    }
  }

  privacyPolicyLink () {
    window.open('https://docs.google.com/document/d/1dsvVWhD7fVx4Rr7nj9sOWUwLRxVvN0fh/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true', '_blank')?.focus()

    appAnalytics.privacyPolicyLink.track(this, {})
  }

  termsOfServiceLink () {
    window.open('https://docs.google.com/document/d/1lDdXZqBkGL7bKhIl-pSwW6H_Y4CDHMR4/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true', '_blank')?.focus()

    appAnalytics.termsOfServiceLink.track(this, {})
  }
}
